<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="code" placeholder="请输入送检单号" />
            <a-input v-model:value="projectCode" placeholder="请输入项目编码" />
            <a-input v-model:value="projectName" placeholder="请输入项目名称" />
            <a-input v-model:value="materialCode" placeholder="请输入物料编码" />
            <a-input v-model:value="materialName" placeholder="请输入物料名称" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
    emits: ["search"],
    setup(props, { emit }) {
        const state = reactive({
            code: undefined,
            projectCode: undefined,
            projectName: undefined,
            materialCode: undefined,
            materialName: undefined,
        });

        const reset = () => {
            state.code = undefined;
            state.projectCode = undefined;
            state.projectName = undefined;
            state.materialCode = undefined;
            state.materialName = undefined;
            search();
        };

        const search = () => {
            let { code, projectCode, projectName, materialCode, materialName } = state;
            state.code = code?.trim();
            state.projectCode = projectCode?.trim();
            state.projectName = projectName?.trim();
            state.materialCode = materialCode?.trim();
            state.materialName = materialName?.trim();
            emit("search", state);
        };

        return {
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
