<template>
    <InventoryFile
        v-model:inventoryFileVisible="inventoryFileVisible"
        selectType="checkbox"
        @onSelect="onSelectInventoryFile"
    />
    <div style="text-align: right; margin-bottom: 16px" v-if="allowOperate">
        <a-button type="primary" size="small" @click="inventoryFileVisible = true">添加</a-button>
    </div>
    <a-table
        style="margin-bottom: 20px"
        :dataSource="data"
        :columns="comColumns"
        bordered
        size="small"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ record.index }}
            </template>
            <template v-if="column.dataIndex === 'count'">
                <a-input-number
                    v-if="allowOperate"
                    v-model:value="record.count"
                    :min="0"
                    :precision="0"
                    style="width: 100%"
                />
                <span v-else>{{ record.count }}</span>
            </template>
            <template v-if="column.dataIndex === 'operate' && allowOperate">
                <a @click="handleDelete(index)">删除</a>
            </template>
        </template>
    </a-table>
</template>
<script>
import { computed, reactive, toRefs, watch } from "vue";
import InventoryFile from "@/components/InventoryFile";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "物料编码",
        dataIndex: "code",
    },
    {
        title: "物料名称",
        dataIndex: "name",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "供应商",
        dataIndex: "supplier",
    },
    {
        title: "物料数量",
        dataIndex: "count",
        width: 100,
    },
    {
        title: "单位",
        dataIndex: "primaryUom",
        width: 100,
    },
];
export default {
    props: {
        materialList: {
            default: [],
        },
        allowOperate: {
            default: true,
        },
    },
    components: { InventoryFile },
    emits: ["onDeleteResult", "onChangeResult"],
    setup(props, { emit }) {
        const state = reactive({
            data: [],
            inventoryFileVisible: false,
            comColumns: computed(() => {
                const _columns = [...columns];
                if (props.allowOperate) {
                    _columns.push({
                        title: "操作",
                        dataIndex: "operate",
                        fixed: "right",
                        width: 80,
                    });
                }
                return _columns;
            }),
        });

        watch(
            () => props.materialList,
            newVal => {
                state.data = newVal?.map((item, index) => ({ ...item, index: index + 1 }));
            }
        );
        watch(
            () => state.data,
            newVal => {
                emit("onChange", newVal);
            },
            { deep: true }
        );

        const onSelectInventoryFile = row => {
            state.data = [...state.data, ...row]?.map((item, index) => ({
                ...item,
                index: index + 1,
            }));
        };

        const handleDelete = index => {
            state.data.splice(index, 1);
        };

        return {
            ...toRefs(state),
            handleDelete,
            onSelectInventoryFile,
        };
    },
};
</script>
