<template>
    <a-drawer v-model:visible="visible" :closable="false" @close="handleCancel" title="送检单详情">
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-tabs v-model:value="activeTabKey">
            <a-tab-pane :key="1" tab="基础信息">
                <a-descriptions title="">
                    <a-descriptions-item label="送检单号">
                        {{ orderInfo?.code }}
                    </a-descriptions-item>

                    <a-descriptions-item label="优先级">
                        {{ orderInfo?.priorityZh }}
                    </a-descriptions-item>

                    <a-descriptions-item label="发料日期">
                        {{ orderInfo?.sendMaterialDate }}
                    </a-descriptions-item>

                    <a-descriptions-item label="项目名称">
                        {{ orderInfo?.projectName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="项目编码">
                        {{ orderInfo?.projectCode }}
                    </a-descriptions-item>

                    <a-descriptions-item label="送检物料品类数">
                        {{ orderInfo?.materialCategoryCount }}
                    </a-descriptions-item>

                    <a-descriptions-item label="送检部门">
                        {{ orderInfo?.senderDept }}
                    </a-descriptions-item>

                    <a-descriptions-item label="送检日期">
                        {{ orderInfo?.sendDate }}
                    </a-descriptions-item>
                    <a-descriptions-item label="送检人">
                        {{ orderInfo?.senderDisplayName }}
                    </a-descriptions-item>

                    <a-descriptions-item label="备注" :span="3">
                        {{ orderInfo?.remark }}
                    </a-descriptions-item>
                    <a-descriptions-item label="附件" :span="3">
                        <div class="filebox">
                            <a
                                v-for="item in orderInfo?.files"
                                :href="`${fileURL}/download/${item?.id}`"
                            >
                                {{ item?.fileName }}
                            </a>
                        </div>
                    </a-descriptions-item>
                    <a-descriptions-item label="制单人">
                        {{ orderInfo?.creatorDisplayName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="制单时间" :span="2">
                        {{ orderInfo?.createdTime }}
                    </a-descriptions-item>
                </a-descriptions>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="送检物料清单" force-render>
                <MaterialList :materialList="materialList" :allowOperate="false" />
            </a-tab-pane>
        </a-tabs>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiSendCheckOrderDetail } from "@/api/qualityCheck";
import { getStatusColor } from "@/utils/common";
import MaterialList from "../materialList";
import { fileURL } from "@/utils/config";
export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId"],
    components: {
        MaterialList,
    },
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            visible: false,
            materialList: [],
            activeTabKey: 1,
            orderInfo: null,
            operateData: [],
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getDetailData = async id => {
            let res = await apiSendCheckOrderDetail(id);
            if (res.status === "SUCCESS") {
                state.orderInfo = res.data;
                state.materialList = res?.data?.materials.map(item => ({
                    ...item,
                    ...item.erpMaterial,
                }));
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            fileURL,
            ...toRefs(state),
            handleCancel,
            getStatusColor,
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
