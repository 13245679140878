<template>
    <a-modal
        :visible="Boolean(+id)"
        title="终止"
        @cancel="cancelSureTerminated"
        @ok="handleSureTerminated"
    >
        <p style="margin-bottom: 20px">终止后状态将不可变更</p>
        <a-input v-model:value="terminatedReason" placeholder="请输入终止原因" />
    </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import { showMessage } from "@/utils/common";
export default defineComponent({
    props: {
        id: {
            default: null,
        },
        api: {
            default: null,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            terminatedReason: "",
        });

        const cancelSureTerminated = () => {
            state.terminatedId = "";
            state.terminatedReason = "";
            emit("update:id", null);
        };

        const handleSureTerminated = async () => {
            if (state.terminatedReason?.trim() == "") {
                return showMessage("info", "请输入终止原因");
            }
            let res = await props?.api({
                id: props?.id,
                reason: state.terminatedReason,
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                cancelSureTerminated();
                emit("success");
            }
        };

        return {
            ...toRefs(state),
            cancelSureTerminated,
            handleSureTerminated,
        };
    },
});
</script>
