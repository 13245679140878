<template>
    <a-modal
        v-model:visible="visible"
        title="存货列表"
        width="1200px"
        okText="确定"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.code" placeholder="请输入存货编码" />
            <a-input v-model:value="params.name" placeholder="请输入存货名称" />
            <a-button type="primary" @click="handleSearch">搜索</a-button>
        </a-space>

        <div class="table-content">
            <InventoryTree @select="onSelectTree" />
            <a-table
                :columns="columns"
                :data-source="tableData"
                :loading="loading"
                bordered
                size="small"
                :pagination="pagination"
                @change="paginationChange"
                :row-selection="{
                    selectedRowKeys,
                    onChange: onSelect,
                    type: selectType,
                }"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                </template>
            </a-table>
        </div>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiInventoryFileList } from "@/api";
import Select from "@/components/Select";
import InventoryTree from "../InventoryTree";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "存货编码",
        dataIndex: "code",
    },
    {
        title: "存货名称",
        dataIndex: "name",
    },
    {
        title: "单位",
        dataIndex: "primaryUom",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
];

export default defineComponent({
    props: {
        inventoryFileVisible: {
            default: false,
        },
        selectType: {
            default: "radio",
        },
        notExistsQcPlan: {
            default: false,
        },
    },
    emits: ["update:inventoryFileVisible", "onSelect"],
    components: {
        Select,
        InventoryTree,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            tableData: [],
            selectedRowKeys: [],
            selectedRows: [],
            keyWords: "",
            categoryCode: "",
            params: {
                code: "",
                name: "",
            },
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const handleCancel = () => {
            state.pagination.current = 1;
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            emit("update:inventoryFileVisible", false);
        };

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                params: { code, name },
            } = state;
            const data = {
                pageNum,
                pageSize,
                queryParam: {
                    categoryCode: state.categoryCode,
                    code: code?.trim(),
                    name: name?.trim(),
                    notExistsQcPlan: props.notExistsQcPlan,
                },
            };
            let res = await apiInventoryFileList(data);
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        const handleSearch = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const paginationChange = pagination => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
            state.pagination = pagination;
            getTabelData();
        };

        const onSelect = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleSubmit = async () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择存货");
            }
            emit("onSelect", state.selectedRows);
            handleCancel();
        };

        const onSelectTree = value => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
            state.pagination.current = 1;
            state.categoryCode = value;
            getTabelData();
        };

        watch(
            () => props.inventoryFileVisible,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    state.keyWords = "";
                    state.categoryCode = "";
                    getTabelData();
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            paginationChange,
            getTabelData,
            handleSubmit,
            onSelect,
            handleSearch,
            onSelectTree,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-space {
    margin-right: 16px;
    margin-bottom: 16px;
    .ant-input,
    .ant-select {
        width: 160px;
    }
}

.table-content {
    display: flex;
    height: auto !important;
    .ant-table-wrapper {
        flex: 1;
        width: 0;
    }
}
</style>
