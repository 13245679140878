<template>
    <UserList
        v-model:userListVisible="userListVisible"
        @onSelect="onSelectUser"
        selectType="radio"
    />
    <a-modal
        v-model:visible="visible"
        title="创建送检单"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="1150px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="送检单号"
                        name="code"
                        :rules="[{ required: true, message: '请获取送检单号，刷新重试获取' }]"
                    >
                        <a-input
                            :disabled="true"
                            v-model:value="formData.code"
                            :maxlength="100"
                            showCount
                        ></a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item
                        label="优先级"
                        name="priority"
                        :rules="[{ required: true, message: '请选择优先级' }]"
                    >
                        <Select
                            v-model:value="formData.priority"
                            :options="priorityOptinos"
                            labelKey="valueZh"
                        />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="发料日期"
                        name="sendMaterialDate"
                        :rules="[{ required: true, message: '请选择发料日期' }]"
                    >
                        <a-date-picker
                            v-model:value="formData.sendMaterialDate"
                            valueFormat="YYYY-MM-DD"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item
                        label="项目名称"
                        name="projectName"
                        :rules="[
                            { required: true, message: '请输入项目名称' },
                            { validator: checkSpaceAll },
                        ]"
                    >
                        <a-input
                            style="width: 305px; margin-right: 20px"
                            v-model:value="formData.projectName"
                            :maxlength="100"
                            showCount
                        />
                        <a-checkbox v-model:checked="formData.stockUp">备货来料</a-checkbox>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="项目编码"
                        name="projectCode"
                        :rules="[
                            { required: true, message: '请输入项目编码' },
                            { validator: checkSpaceAll },
                        ]"
                    >
                        <a-input v-model:value="formData.projectCode" :maxlength="100" showCount />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-descriptions title="送检物料清单"></a-descriptions>

            <MaterialList @onChange="data => (materialList = data)" />

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="送检日期"
                        name="sendDate"
                        :rules="[{ required: true, message: '请选择送检日期' }]"
                    >
                        <a-date-picker
                            v-model:value="formData.sendDate"
                            valueFormat="YYYY-MM-DD"
                            style="width: 100%"
                        />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item
                        label="送检部门"
                        name="senderDeptCode"
                        :rules="[{ required: true, message: '请选择送检部门' }]"
                    >
                        <DeptSelect
                            v-model:value="formData.senderDeptCode"
                            :useLable="true"
                            @onChange="onChangeDept"
                        />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item
                        label="送检人"
                        name="senderDisplayName"
                        :rules="[{ required: true, message: '请选择送检人' }]"
                    >
                        <SelectInput
                            :title="formData.senderDisplayName"
                            :onClick="() => (userListVisible = true)"
                            :showDeleteIcon="false"
                        ></SelectInput>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-form-item
                label="备注"
                name="remark"
                :label-col="{ span: 3 }"
                :wrapper-col="{ span: 21 }"
                :rules="[{ validator: checkSpaceAll }]"
            >
                <a-textarea
                    v-model:value="formData.remark"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-row :span="24" class="margin-bottom">
                <a-col :span="12">
                    <a-form-item label="附件" name="files">
                        <UploadFile
                            businessType="EM_INSPECTION_REQUEST_FILE"
                            :files="formData.files"
                            @onSuccess="fileInfo => onSuccess(fileInfo)"
                            @deleteFile="index => onDeleteFile(index)"
                        ></UploadFile>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-form-item :wrapper-col="{ offset: 21, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiSendCheckOrderUpdate, apiSendCheckOrderUpdateIndexData } from "@/api/qualityCheck";
import Select from "@/components/Select";
import ProductList from "@/components/ProductList";
import OrderCategory from "@/components/CategoryModal";
import SaleOrder from "@/components/SaleOrder";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import SelectInput from "@/components/SelectInput";
import MaterialList from "../materialList";
import DeptSelect from "@/components/DeptSelect";
import UserList from "@/components/UserList";
import UploadFile from "@/components/UploadFile";
import { truncate } from "fs";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk", "update:upDateId"],
    components: {
        Select,
        OrderCategory,
        ProductList,
        SaleOrder,
        SelectInput,
        MaterialList,
        DeptSelect,
        UserList,
        UploadFile,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            priorityOptinos: [],
            formRef: null,
            formData: {
                id: "",
                code: "",
                priority: "",
                sendMaterialDate: "",
                projectName: "",
                projectCode: "",
                stockUp: false,
                sendDate: "",
                senderDept: "",
                senderDeptCode: "",
                senderDisplayName: "",
                remark: "",
                files: [],
            },
            userListVisible: false,
            materialList: [],
        });

        const autoGetCode = async () => {
            let code = await getAutoCreateCode("EM_INSPECTION_REQUEST");
            state.formData.code = code;
        };

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            const { formData, materialList } = state;
            if (!materialList?.length) return showMessage("info", "请添加物料清单");
            for (let item of materialList) {
                if (item.count == undefined) return showMessage("info", "请填写物料数量");
            }
            const reqData = {
                ...formData,
                fileIds: formData.files.map(item => item?.id),
                materialSaveDatas: materialList.map(item => ({
                    materialCode: item.code,
                    count: item.count,
                })),
            };
            let res = await apiSendCheckOrderUpdate(reqData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiSendCheckOrderUpdateIndexData(id);
            if (res.status === "SUCCESS") {
                const { editInfo, priorityCandidate } = res?.data;
                state.priorityOptinos = priorityCandidate;
                if (editInfo) {
                    Object.keys(state.formData).forEach(key => {
                        state.formData[key] = editInfo ? editInfo?.[key] : undefined;
                    });
                }
            }
        };
        const checkSpaceAll = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onSelectUser = ([user]) => {
            state.formData.senderDisplayName = user?.displayName;
        };

        const onSuccess = fileInfo => {
            state.formData.files = [...state.formData.files, fileInfo];
        };

        const onDeleteFile = index => {
            state.formData.files.splice(index, 1);
        };

        const onChangeDept = (value, label) => {
            state.formData.senderDeptCode = value;
            state.formData.senderDept = label;
        };

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    autoGetCode();
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            onChangeDept,
            checkSpaceAll,
            onSuccess,
            onDeleteFile,
            onSelectUser,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
.margin-bottom {
    margin-bottom: 20px;
}
</style>
